import React from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Heading,
  Link as ChakraLink,
  Stack,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

const MoreLearningSection = () => {
  return (
    <Stack spacing={4}>
      <Heading as="h3" size="md">
        Continuing your climate journey
      </Heading>
      <Text>
        Continue your climate journey by building your very own carbon reduction
        roadmap or learning some climate terminology.
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
        <Card alignItems="center" borderRadius="2xl">
          <CardHeader pb="8px">
            <Heading size="md">Personal Carbon Reduction Roadmap</Heading>
          </CardHeader>
          <CardBody pt="8px" pl="20px">
            <Text>Create your own personal carbon reduction roadmap.</Text>
          </CardBody>
          <CardFooter>
            <Center>
              <Button
                as={ChakraLink}
                colorScheme="green"
                color="white"
                href="https://gravel-pelican-328.notion.site/Zopeful-Personal-Climate-Roadmap-v0-1-Draft-65c6ed7a0c0247d3bf400df6ef7cbf59"
                isExternal
                w="180px"
              >
                Build your roadmap
              </Button>
            </Center>
          </CardFooter>
        </Card>

        <Card alignItems="center" borderRadius="2xl">
          <CardHeader pb="8px">
            <Heading size="md">Climate buzzword dictionary</Heading>
          </CardHeader>
          <CardBody pt="8px" pl="20px">
            <Text>
              This is a summary of all the climate related terminology,
              buzzwords and acronymns used in our courses.
            </Text>
          </CardBody>
          <CardFooter>
            <Center>
              <Button
                as={ReactRouterLink}
                colorScheme="green"
                color="white"
                to="/dictionary"
                w="180px"
              >
                Learn more
              </Button>
            </Center>
          </CardFooter>
        </Card>

        <Card alignItems="center" borderRadius="2xl">
          <CardHeader pb="8px">
            <Heading size="md">Climate@Work Survey</Heading>
          </CardHeader>
          <CardBody pt="8px" pl="20px">
            <Text>
              Our global survey into attitudes and support for climate in your
              workplace. What you really think, really matters. Have your say
              now!
            </Text>
          </CardBody>
          <CardFooter>
            <Center>
              <Button
                as={ChakraLink}
                colorScheme="green"
                color="white"
                href="https://research.typeform.com/to/UNamBcuC"
                isExternal
                w="180px"
              >
                Take the survey
              </Button>
            </Center>
          </CardFooter>
        </Card>
      </SimpleGrid>
    </Stack>
  );
};

export default MoreLearningSection;

import React from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  VStack,
  Heading,
  Box,
  Divider,
  Flex,
  Text,
  Spacer,
} from '@chakra-ui/react';

import CurrencyNumber from '../../utils/CurrencyNumber';
import PurchaseButton from './PurchaseButton';
import { PaymentLink } from '../../types/interfaces';

const PurchaseCard = (props: { paymentLink: PaymentLink }) => {
  const {
    stripePriceId,
    kgCarbonRemoval,
    description,
    currencyType,
    currencyAmount,
    isSubscription,
  } = props.paymentLink;
  const carbonRemovalString =
    kgCarbonRemoval >= 1000
      ? `${kgCarbonRemoval / 1000} tonnes`
      : `${kgCarbonRemoval}kg`;
  const currencyNumber = new CurrencyNumber(currencyAmount / 100, currencyType);
  const priceString = `For ${currencyNumber.toString()}${
    isSubscription ? ' per month' : ''
  }`;

  return (
    <Card w="100%" h="100%" align="center" borderRadius="xl">
      <CardHeader>
        <VStack mt="5" spacing={2.5} textAlign="center">
          <Heading size="sm">
            {isSubscription ? 'REMOVE MONTHLY' : 'ONE TIME REMOVAL'}
          </Heading>
          <Heading size="lg" color="green.500">
            {carbonRemovalString}
          </Heading>
          <Heading size="sm">{priceString}</Heading>
        </VStack>
      </CardHeader>
      <Box w="80%">
        <Divider size="sm" />
      </Box>
      <CardBody textAlign="center" h="100%">
        <Flex direction="column" h="100%">
          <Box>
            {description.split('\n').map((text) => (
              <Text key={text} pb="8px">
                {text}
              </Text>
            ))}
          </Box>
          <Spacer />
          <Box mb={4}>
            <PurchaseButton
              stripePriceId={stripePriceId}
              isSubscription={isSubscription}
            />
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PurchaseCard;

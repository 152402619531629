import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Circle,
  Flex,
  Image,
  Link as ChakraLink,
  Heading,
  HStack,
  Text,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react';

import { SolutionTypeConfig } from '../../types/types';
import { PortfolioPartnerData, SolutionTypeInfo } from '../../types/interfaces';

const Markdown = React.lazy(() => import('react-markdown'));

const PartnerCard = (props: { partnerData: PortfolioPartnerData }) => {
  const solutionTypeInfo: SolutionTypeInfo =
    SolutionTypeConfig[props.partnerData.solutionType];
  const [isSmallScreen] = useMediaQuery('(max-width: 48em)');

  const renderHeader = () => {
    return (
      <CardHeader pb={1}>
        <Heading as="h2" size="lg">
          {props.partnerData.name}
        </Heading>
        <HStack spacing="2">
          <Circle size="8px" bg={solutionTypeInfo.color} />
          <Text>{solutionTypeInfo.name}</Text>
        </HStack>
      </CardHeader>
    );
  };

  const renderDescription = () => {
    return (
      <CardBody>
        <Stack spacing={3}>
          <Markdown>{props.partnerData.description}</Markdown>
          <Text>
            <ChakraLink
              href={props.partnerData.partnerInformationLink}
              isExternal
              color="teal.500"
            >
              {`Read more about ${props.partnerData.name}`}
            </ChakraLink>
          </Text>
        </Stack>
      </CardBody>
    );
  };

  const renderImageAndFacts = () => {
    return (
      <Box>
        <CardHeader>
          <Image
            src={props.partnerData.staticImageLink}
            alt={`Photo of ${props.partnerData.name}`}
            borderRadius="xl"
          />
        </CardHeader>
        <CardBody>
          <HStack spacing={2} w="100%" alignItems="start">
            <Stack w="50%" spacing={4}>
              <Text>
                Solution Type
                <br />
                <ChakraLink
                  color="teal.500"
                  as={ReactRouterLink}
                  to={`/solution-type/${props.partnerData.solutionType}`}
                >
                  <strong>{solutionTypeInfo.name}</strong>
                </ChakraLink>
              </Text>
              <Text>
                Permanence
                <br />
                <strong>{props.partnerData.permanence}</strong>
              </Text>
              <Text>
                Additionality
                <br />
                <strong>{props.partnerData.additionality}</strong>
              </Text>
            </Stack>

            <Stack w="50%" spacing={4}>
              <Text>
                Company Stage <br />
                <ChakraLink
                  color="teal.500"
                  href="https://zopeful.com/blog/carbon-removal-portfolio-fund-methodology"
                  isExternal
                >
                  <strong>{props.partnerData.companyStage}</strong>
                </ChakraLink>
              </Text>
              <Text>
                Location <br />
                <strong>{props.partnerData.location}</strong>
              </Text>
            </Stack>
          </HStack>
        </CardBody>
      </Box>
    );
  };

  const renderSmallScreenCard = () => {
    return (
      <Stack spacing={1} alignItems="start">
        {renderHeader()}
        {renderImageAndFacts()}
        {renderDescription()}
      </Stack>
    );
  };
  const renderCard = () => {
    return (
      <HStack spacing={2} alignItems="start">
        <Flex flex="1" direction="column">
          {renderHeader()}
          {renderDescription()}
        </Flex>
        <Flex flex="1" direction="column">
          {renderImageAndFacts()}
        </Flex>
      </HStack>
    );
  };

  return (
    <Card maxW="m" borderRadius="xl">
      {isSmallScreen ? renderSmallScreenCard() : renderCard()}
    </Card>
  );
};

export default PartnerCard;

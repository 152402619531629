import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Image,
  Flex,
  Link,
  Heading,
  HStack,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import { SolutionTypeData } from '../../types/interfaces';

const Markdown = React.lazy(() => import('react-markdown'));

const SolutionTypeCard = (props: { solutionTypeData: SolutionTypeData }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 48em)');

  const renderHeader = () => {
    return (
      <CardHeader pb={1}>
        <Heading as="h2" size="lg">
          {props.solutionTypeData.name}
        </Heading>
      </CardHeader>
    );
  };

  const renderDescription = () => {
    return (
      <CardBody>
        <Stack spacing={3}>
          <Markdown>{props.solutionTypeData.description}</Markdown>
          <Text>
            <Link
              href={props.solutionTypeData.solutionTypeInformationLink}
              isExternal
              color="teal.500"
            >
              {`Read more about ${props.solutionTypeData.name}`}
            </Link>
          </Text>
        </Stack>
      </CardBody>
    );
  };

  const renderImageAndFacts = () => {
    return (
      <Box>
        <CardHeader>
          <Image
            src={props.solutionTypeData.staticImageLink}
            alt="Placeholder"
            borderRadius="xl"
          />
        </CardHeader>
        <CardBody>
          <HStack spacing={2} w="100%" alignItems="start">
            <Stack w="50%" spacing={4}>
              <Text>
                Permanence
                <br />
                <strong>{props.solutionTypeData.permanence}</strong>
              </Text>
              <Text>
                Additionality
                <br />
                <strong>{props.solutionTypeData.additionality}</strong>
              </Text>
              <Text>
                Scaling Potential
                <br />
                <strong>{props.solutionTypeData.scalingPotential}</strong>
              </Text>
            </Stack>

            <Stack w="50%" spacing={4}>
              <Text>
                Co-benefits
                <br />
                <strong>{props.solutionTypeData.coBenefits}</strong>
              </Text>
              <Text>
                Verifiability
                <br />
                <strong>{props.solutionTypeData.verifiability}</strong>
              </Text>
            </Stack>
          </HStack>
        </CardBody>
      </Box>
    );
  };

  const renderSmallScreenCard = () => {
    return (
      <Stack spacing={1} alignItems="start">
        {renderHeader()}
        {renderImageAndFacts()}
        {renderDescription()}
      </Stack>
    );
  };
  const renderCard = () => {
    return (
      <HStack spacing={2} alignItems="start">
        <Flex flex="1" direction="column">
          {renderHeader()}
          {renderDescription()}
        </Flex>
        <Flex flex="1" direction="column">
          {renderImageAndFacts()}
        </Flex>
      </HStack>
    );
  };

  return (
    <Card maxW="m" borderRadius="xl">
      {isSmallScreen ? renderSmallScreenCard() : renderCard()}
    </Card>
  );
};

export default SolutionTypeCard;

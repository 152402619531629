import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Button,
  Center,
  Image,
  GridItem,
  Heading,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';

const Markdown = React.lazy(() => import('react-markdown'));

const InformationPageLinkCard = (props: {
  imageLink: string;
  heading: string;
  description: string;
  pageLink: string;
}) => {
  return (
    <SimpleGrid
      bgColor="white"
      maxW="m"
      columns={{ base: 1, md: 5 }}
      borderRadius="xl"
      paddingY={6}
      paddingX={8}
      spacing={4}
    >
      <GridItem colSpan={{ base: 1, md: 2 }} h="100%">
        <Center>
          <Image
            maxH="250px"
            objectFit="contain"
            src={props.imageLink}
            alt={`Photo for ${props.heading}`}
            borderRadius="xl"
          />
        </Center>
      </GridItem>

      <GridItem colSpan={{ base: 1, md: 3 }} h="100%">
        <Stack spacing={2} h="100%">
          <Heading as="h2" size="md">
            {props.heading}
          </Heading>
          <Markdown>{props.description}</Markdown>
          <Button
            as={ReactRouterLink}
            to={props.pageLink}
            colorScheme="green"
            alignSelf="flex-end"
            mt={4}
          >
            Read more
          </Button>
        </Stack>
      </GridItem>
    </SimpleGrid>
  );
};

export default InformationPageLinkCard;
